
import { defineComponent, ref } from "vue";
import { questions } from "../ts/db-questions";
import NavButtons from "@/db/components/NavButtons.vue";
import PotList from "@/db/components/assets/PotList.vue";
import { StatePensionData } from "../ts/api/statePensionData";
import { formatter } from "../ts/formatter";
import { pensionPotFactory } from "@/db/ts/assets/pensionPotFactory";
import { savingPotFactory } from "@/db/ts/assets/savingPotFactory";
import { finalSalaryPotFactory } from "@/db/ts/assets/finalSalaryPotFactory";
import api from "../ts/api";
import Pot from "@/db/ts/assets/pot";
import { constants } from "@/db/ts/constants";

const enum HavesSection {
  WhatYouHave,
  PreCalcMsg,
  Answers
}

export default defineComponent({
  name: "Haves",
  components: {
    NavButtons,
    PotList
  },
  setup() {
    const definedBenefitPots = ref(null);
    const pensionPots = ref(null);
    const savingsPots = ref(null);

    return {
      definedBenefitPots,
      pensionPots,
      savingsPots
    };
  },
  data() {
    return {
      currentSection: HavesSection.WhatYouHave,
      formatter: formatter,
      pensionPotFactory,
      savingPotFactory,
      finalSalaryPotFactory
    };
  },
  methods: {
    isActiveSection(havesSection: HavesSection) {
      return havesSection == this.currentSection;
    },
    onPreviousClicked() {
      this.$store.commit("updateHasDeficitCalcCompleted", false);
      if (this.currentSection == HavesSection.WhatYouHave) {
        this.$emit("previousClicked");
      } else if (this.currentSection == HavesSection.PreCalcMsg) {
        this.currentSection = HavesSection.WhatYouHave;
      } else if (this.currentSection == HavesSection.Answers) {
        this.currentSection = HavesSection.PreCalcMsg;
      }
    },
    onNextClicked() {
      if (this.currentSection == HavesSection.WhatYouHave) {
        this.currentSection = HavesSection.PreCalcMsg;
      } else if (this.currentSection == HavesSection.PreCalcMsg) {
        let allPots = this.getAllPots();
        if (allPots == null) {
          allPots = new Array<Pot>();
        }
        this.$store.commit("updateAllOtherAssets", allPots);

        // eslint-disable-next-line no-inner-declarations
        function getPotDetail(pot: Pot) {
          return {
            potType: pot.potType,
            value: pot.currentValue,
            payableFrom: pot.payableFrom
          };
        }

        const potDetail = allPots.map(getPotDetail);

        const viewOnRisk = questions.getViewOnRisk();
        let assetGrowthRate = 2;
        if (viewOnRisk == constants.Risk.Yes) {
          assetGrowthRate = 2.5;
        }

        const declaredOrEstimatedFullPension = questions.getDeclaredOrEstimatedFullPension();

        api.getFinancialPosition(
          this.$store.state.userId,
          questions.getSexAnswer(),
          questions.getDateOfBirthAnswer(),
          questions.getIncome(),
          this.$store.state.statePensionData.amount,
          declaredOrEstimatedFullPension,
          questions.getReducedPension(),
          questions.getReducedPensionCashAmount(),
          questions.getCurrentSchemeInflation(),
          assetGrowthRate,
          questions.getDebt(),
          questions.getChoiceOfIncome(),
          potDetail
        );

        this.currentSection = HavesSection.Answers;
      } else if (this.currentSection == HavesSection.Answers) {
        this.$emit("nextClicked");
      }
    },
    getAllPots() {
      const allPots = [];
      const addToAllPots = pots => pots.forEach(p => allPots.push(p));
      addToAllPots(this.definedBenefitPots.getPots());
      addToAllPots(this.pensionPots.getPots());
      addToAllPots(this.savingsPots.getPots());
      return allPots;
    }
  },
  computed: {
    hasFinancialDeficitCalcCompleted() {
      return this.$store.state.hasDeficitCalcCompleted;
    },
    hasFinancialDeficit() {
      return this.$store.state.hasFinancialDeficit;
    },
    canExpectStatePension() {
      return this.statePensionData.amount > 0;
    },
    statePensionTitle() {
      if (this.statePensionData.amount > 0) {
        return "You can also expect a state pension";
      }
      return "You cannot expect a state pension";
    },
    showWhatYouHave() {
      return this.isActiveSection(HavesSection.WhatYouHave);
    },
    showPreCalcMessage() {
      return this.isActiveSection(HavesSection.PreCalcMsg);
    },
    showAnswers() {
      return this.isActiveSection(HavesSection.Answers);
    },
    hasFullPension() {
      const fullPension = questions.getFullPension();
      return fullPension != null && fullPension != 0;
    },
    hasReducedPension() {
      const reducedPension = questions.getReducedPension();
      return reducedPension != null && reducedPension != 0;
    },
    currentSchemeDescriptions(): Array<string> {
      const descriptions = [];

      const hasGotIllustration = questions.getHasReceivedIllustration();

      if (hasGotIllustration && this.hasFullPension) {
        descriptions.push(
          `A starting pension of ${this.fullPension} per year, or,`
        );
      } else if (!hasGotIllustration) {
        const estimatedPension = questions.getDeclaredOrEstimatedFullPension();
        descriptions.push(
          `An estimated starting pension of ${formatter.formatCurrency(
            estimatedPension
          )} per year, or,`
        );
      }

      if (this.hasReducedPension) {
        const reducedPensionDescription = `A starting pension of ${this.reducedPension} per year, plus tax free cash of ${this.reducedPensionCashAmount}`;
        descriptions.push(reducedPensionDescription);
      } else {
        const calcdReduced = formatter.formatCurrency(
          this.$store.state.calculatedReducedPension
        );
        const calcdReducedCash = formatter.formatCurrency(
          this.$store.state.calculatedReducedPensionCash
        );
        const estimatedReducedDescription = `We have estimated you could instead take a reduced pension of ${calcdReduced} plus tax free cash of ${calcdReducedCash}`;
        descriptions.push(estimatedReducedDescription);
      }

      return descriptions;
    },
    fullPension: function() {
      const formatted = formatter.formatCurrency(questions.getFullPension());
      return formatted;
    },
    reducedPension: function() {
      return formatter.formatCurrency(questions.getReducedPension());
    },
    reducedPensionCashAmount: function() {
      return formatter.formatCurrency(questions.getReducedPensionCashAmount());
    },
    statePensionData: function(): StatePensionData {
      return this.$store.state.statePensionData;
    },
    getStartingIncomeStatement: function(): string {
      const income = formatter.formatCurrency(questions.getIncome());
      const isIncreasing = questions.getChoiceOfIncome() == "Increasing";
      let changeStatement = "which does not increase each year.";
      if (isIncreasing) {
        changeStatement = "which increases each year.";
      }
      return `You would like a total starting income of ${income} ${changeStatement}`;
    },
    showPreviousButton: function(): boolean {
      return (
        this.currentSection == HavesSection.PreCalcMsg ||
        this.currentSection == HavesSection.Answers
      );
    }
  }
});
