import moment from "moment";
import IPotFactory from "./iPotFactory";
import Pot from "./pot";
import PotType from "./potType";

class FinalSalaryPotFactory implements IPotFactory {
  createPot(
    potNumber: number,
    currentValue: number,
    willMakeFutureContribs: boolean,
    monthlyFutureContrib: number,
    futureContribsWillIncrease: boolean,
    payableFrom: number
  ): Pot {
    const name = "Final Salary " + potNumber;

    return new Pot(
      PotType.DefinedBenefit,
      potNumber,
      name,
      currentValue,
      willMakeFutureContribs,
      monthlyFutureContrib,
      futureContribsWillIncrease,
      payableFrom
    );
  }
}

const finalSalaryPotFactory = new FinalSalaryPotFactory();

export { finalSalaryPotFactory };
