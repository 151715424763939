import { ConfirmationBehaviour, Question } from "./db-core";
import { formatter } from "./formatter";
import { TransferValueFollowUpQuestionEvaluator } from "./questions/FollowUpQuestionEvaluator";

class TranValueConfirmationBehaviour extends ConfirmationBehaviour {
  public IsConfirmationRequired(question: Question): boolean {
    const selectedAnswer = question.getSelectedAnswer();
    return (
      selectedAnswer.response <
      TransferValueFollowUpQuestionEvaluator.MinTransferValue
    );
  }

  public GetConfirmationText(question: Question): string {
    console.log("tv question", question.getSelectedAnswer());
    const selectedAnswer = question.getSelectedAnswer();
    const response = formatter.formatCurrency(selectedAnswer.response);
    return `Is your transfer value of ${response} correct?`;
  }
}

export { TranValueConfirmationBehaviour };
