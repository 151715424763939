import { Configuration } from "./configuration";
import api from "./api";
import store from "../store";
import Result from "./result";

class App {
  public async initialise() {
    const configElement = document.getElementById("config");
    let config: Configuration;
    if (configElement) {
      config = JSON.parse(configElement.innerHTML);
    } else {
      config = await this.retrieveConfig();
    }
    store.commit("updateConfiguration", config);
  }

  public async retrieveConfig(): Promise<Configuration> {
    return await api.getConfig();
  }

  public async generateCodes(codes: Array<string>): Promise<Result> {
    return await api.generateCodes(codes);
  }

  public async deleteCodes(startsWith: string): Promise<Result> {
    return await api.deleteCodes(startsWith);
  }
}

const app = new App();

export { app };
