import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-3" }
const _hoisted_2 = { class: "form-check form-check-inline mb-3" }
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = { class: "form-check form-check-inline mb-3" }
const _hoisted_5 = ["id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.text), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        ref: "yesAnswer",
        id: _ctx.name + 'Yes',
        class: "form-check-input",
        type: "radio",
        name: _ctx.name,
        value: "Yes",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChanged()))
      }, null, 40, _hoisted_3),
      _createElementVNode("label", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onYesClick && _ctx.onYesClick(...args))),
        class: "form-check-label"
      }, "Yes")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("input", {
        ref: "noAnswer",
        id: _ctx.name + 'No',
        class: "form-check-input",
        type: "radio",
        name: _ctx.name,
        value: "No",
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChanged()))
      }, null, 40, _hoisted_5),
      _createElementVNode("label", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onNoClick && _ctx.onNoClick(...args))),
        class: "form-check-label"
      }, "No")
    ])
  ], 64))
}