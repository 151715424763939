class Formatter {
  public formatCurrency(amount: number): string {
    const currencyFormatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0
    });

    try {
      return currencyFormatter.format(amount);
    } catch (error) {
      return "";
    }
  }

  public formatCurrencyForInput(amount: number): string {
    const currency = this.formatCurrency(amount);
    return currency.replace("£", "");
  }
}

const formatter = new Formatter();

export { formatter };
