import axios from "axios";
import { format } from "date-fns";
import { Configuration } from "../ts/configuration";
import ServerOutcome from "../ts/outcome";
import { questions } from "../ts/db-questions";
import { questionNavigator } from "../ts/questionNavigator";
import { StoredAnswer } from "../ts/storedAnswer";
import store from "@/db/store";
import Result from "./result";
import DataResult from "./dataResult";

class API {
  public async getConfig(): Promise<Configuration> {
    const response = await axios.get("/theme/config");
    return response.data as Configuration;
  }

  public async generateCodes(codes: Array<string>): Promise<Result> {
    try {
      const userId = store.state.userId;
      const payload = { userId, codes };
      await axios.post("/db/codes", payload);
      return new Result(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const response = error.response;
        const dataMessage = response.data.message;
        const message = `The codes were not created. ${dataMessage}`;
        return new Result(false, message);
      } else {
        return new Result(false, `An error occurred: ${error.message}`);
      }
    }
  }

  public async deleteCodes(startsWith: string): Promise<Result> {
    try {
      const userId = store.state.userId;
      const response = await axios.delete(`/db/codes?userId=${userId}&startsWith=${startsWith}`);
      const successMessage = response.data.message;
      return new Result(true, successMessage);
  } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const response = error.response;
        const dataMessage = response.data.message;
        const message = `The codes were not deleted. ${dataMessage}`;
        return new Result(false, message);
      } else {
        return new Result(false, `An error occurred: ${error.message}`);
      }
    }
  }

  getStatePensionDetail(sex: string, dob: Date) {
    const dobAsString = format(dob, "dd-MMM-yyyy");
    const resource = `/DB/getStatePensionDetail?sex=${sex}&dateOfBirth=${dobAsString}`;

    axios.get(resource).then((response) => {
      console.log(response.data);
      console.log(response.data.Data.PensionDescription);
      return response.data.Data.PensionDescription;
    });
  }

  verifyAccessCode(accessCode: string): Promise<any> {
    const resource = `/DB/verifyAccessCode?code=${accessCode}`;
    return axios.get(resource);
  }

  setTerminus(userId: string, outcome: ServerOutcome): Promise<any> {
    const resource = "/DB/SetTerminus";

    const answers = questionNavigator.answeredQuestions.map((q) => {
      return new StoredAnswer(q.id, questions.getAnswers(q));
    });

    const data = { userId: userId, outcome: outcome, responses: answers };
    return axios.post(resource, data);
  }

  getFinancialPosition(
    userId: string,
    sex: string,
    dateOfBirth: Date,
    income: number,
    statePension: number,
    fullPension: number,
    reducedPension: number,
    reducedPensionCash: number,
    schemeGrowthRate: number,
    assetGrowthRate: number,
    debt: number,
    incomeType: string,
    allOtherPots: any
  ) {
    const params = {
      params: {
        userId: userId,
        sex: sex,
        dateOfBirth: dateOfBirth,
        income: income,
        statePension: statePension,
        fullPension: fullPension,
        reducedPension: reducedPension,
        reducedPensionCash: reducedPensionCash,
        schemeGrowthRate: schemeGrowthRate,
        assetGrowthRate: assetGrowthRate,
        debt: debt,
        incomeType: incomeType,
        allOtherPots: allOtherPots,
      },
    };

    const resource = "/DB/getFinancialPosition";

    axios.get(resource, params).then((response) => {
      store.commit("updateFinancialDeficit", response.data.Data.userHasDeficit);
      store.commit(
        "updateCalculatedReducedPension",
        response.data.Data.reducedPension
      );
      store.commit(
        "updateCalculatedReducedPensionCash",
        response.data.Data.reducedPensionCash
      );
      store.commit("updateHasDeficitCalcCompleted", true);
    });
  }
}

const api = new API();

export default api;
