import IPotFactory from "./iPotFactory";
import Pot from "./pot";
import PotType from "./potType";

class PensionPotFactory implements IPotFactory {
  createPot(
    potNumber: number,
    currentValue: number,
    willMakeFutureContribs: boolean,
    monthlyFutureContrib: number,
    futureContribsWillIncrease: boolean,
    payableFrom: number
  ): Pot {
    const name = "Pension Pot " + potNumber;
    return new Pot(
      PotType.Pension,
      potNumber,
      name,
      currentValue,
      willMakeFutureContribs,
      monthlyFutureContrib,
      futureContribsWillIncrease,
      payableFrom
    );
  }
}

const pensionPotFactory = new PensionPotFactory();

export { pensionPotFactory };
