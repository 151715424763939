import { questions } from "../ts/db-questions";
import TerminusType from "../ts/terminus-type";

class Terminus {
  private questionTerminusDictionary = new Map<number, TerminusType>();

  public registerTerminusType(questionId: number, terminusType: TerminusType) {
    this.questionTerminusDictionary.set(questionId, terminusType);
  }

  public getTerminusType(questionId: number): TerminusType {
    return this.questionTerminusDictionary.get(questionId);
  }
}

const terminus = new Terminus();

terminus.registerTerminusType(
  questions.youngAndInGoodHealth.id,
  TerminusType.YoungAndInGoodHealth
);

terminus.registerTerminusType(
  questions.transferValueAmountQuestion.id,
  TerminusType.TransferValueTooLow
);

export { terminus };
