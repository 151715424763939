class InputValidator {
  checkNumeric(event) {
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  isNumber(value: string, min: number = null): boolean {
    if (this.isEmptyOrSpaces(value)) {
      return false;
    }

    const parsedNumber = Number(value);

    if (isNaN(parsedNumber)) {
      return false;
    }

    if (!min) {
      return true;
    }

    if (parsedNumber >= min) {
      return true;
    }

    return false;
  }

  isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }
}

const inputValidator = new InputValidator();

export { inputValidator };
