export default class Temporal {
  public static calcAge(dateOfBirth: Date): number {
    return this.calcAgeAtDate(dateOfBirth, new Date());
  }

  public static calcAgeAtDate(dateOfBirth: Date, ageAtDate: Date): number {
    if (ageAtDate <= dateOfBirth) {
      return 0;
    }

    let age = ageAtDate.getFullYear() - dateOfBirth.getFullYear();
    const monthDifference = ageAtDate.getMonth() - dateOfBirth.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && ageAtDate.getDate() < dateOfBirth.getDate())
    ) {
      age--;
    }

    return age;
  }

  public static getFirstOfNextMonth() {
    const now = new Date();
    if (now.getMonth() == 11) {
      return new Date(now.getFullYear() + 1, 0, 1);
    } else {
      return new Date(now.getFullYear(), now.getMonth() + 1, 1);
    }
  }

  public static yearsBetweenDates(dateOne: Date, dateTwo: Date) {
    return Math.floor(this.yearsBetweenDatesExact(dateOne, dateTwo));
  }

  public static yearsBetweenDatesExact(dateOne: Date, dateTwo: Date) {
    let difference = dateTwo.getTime() - dateOne.getTime();
    difference = Math.abs(difference);
    difference = difference / (1000 * 60 * 60 * 24 * 365);
    return difference;
  }
}
