
import { defineComponent } from "vue";
import { Configuration } from "../ts/configuration";

export default defineComponent({
  name: "Start",
  data() {
    return {
      backgroundImageUrl: "../../../Content/images/transfers-rsign-and-toy-robot.jpg"
    };
  },
  mounted() {
    if (this.isOrigen) {
        this.backgroundImageUrl = "../../../Content/images/Origen/origen-db-image-start.jpg";
    }
  },
  computed: {
    startText: function (): string {
      const config: Configuration = this.$store.state.configuration;
      return config.startText[0];
    },
    bulletPoints: function (): string[] {
      const config: Configuration = this.$store.state.configuration;
      return config.startTextBulletPoints;
    },
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`
      };
    },
    isOrigen: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "origen";
    }
  },
  methods: {
    onNextClicked() {
      this.$emit("nextClicked");
    },
  },
});
