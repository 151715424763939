
import { defineComponent, ref } from "vue";
import NavButtons from "@/db/components/NavButtons.vue";

export default defineComponent({
  name: "Risk",
  components: {
    NavButtons
  },
  data() {
    return {
      journeyCompleted: false
    };
  },
  setup() {
    const chkIUnderstandTheRisks = ref(null);

    return {
      chkIUnderstandTheRisks
    };
  },
  methods: {
    checkIfUserHasSignedOff() {
      this.journeyCompleted = this.chkIUnderstandTheRisks.checked;
    },
    onNextClicked() {
      this.$emit("NextClicked");
    },
    onAnswerChanged() {
      this.risksUnderstood = true;
    }
  },
  computed: {
    nextIsEnabled: function() {
      return this.journeyCompleted;
    }
  }
});
