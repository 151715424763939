
import { defineComponent } from "vue";
import TerminusType from "../ts/terminus-type";
import { terminus } from "../ts/terminus";

export default defineComponent({
  name: "TerminusView",
  setup() {
    function setBlockedQuestionId(newQuestionId: number) {
      this.TerminationType = terminus.getTerminusType(newQuestionId);
    }

    return { setBlockedQuestionId };
  },
  data() {
    return {
      TerminusType,
      TerminationType: TerminusType.YoungAndInGoodHealth
    };
  },
  methods: {
    goBackToStart: function() {
      this.$emit("goBackToStart");
    }
  },
  computed: {
    advisor: function(): string {
      return this.$store.state.configuration.advisor;
    },
    isGuiide: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "";
    }
  }
});
