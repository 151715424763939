
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    idKey: { type: String, required: true },
    title: { type: String, required: true }
  },
  setup() {
    const theModal = ref(null);
    const closeButton = ref(null);

    return {
      theModal,
      closeButton
    };
  },
  methods: {
    getModalId() {
      return this.idKey + "Modal";
    },
    showModal() {
      document.getElementById("backdrop").style.display = "block";
      const modalId = this.getModalId();
      document.getElementById(modalId).style.display = "block";
      document.getElementById(modalId).classList.add("show");
    },
    closeModal() {
      document.getElementById("backdrop").style.display = "none";
      const modalId = this.getModalId();
      document.getElementById(modalId).style.display = "none";
      document.getElementById(modalId).classList.remove("show");
    }
  }
});
