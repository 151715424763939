
import { defineComponent, ref, onBeforeUpdate, onMounted } from "vue";
import { Answer, Question, DefaultQuestionTextProvider } from "@/db/ts/db-core";
import AnswerPresentationType from "@/db/ts/answer-presentation-type";
import AnswerView from "@/db/components/Answer.vue";
import NavButtons from "@/db/components/NavButtons.vue";

export default defineComponent({
  name: "QuestionView",
  components: {
    AnswerView,
    NavButtons
  },
  emits: ["previous-clicked", "answered"],
  setup() {
    // todo was going to try to capture dynamic refs for the answers being generated above
    // next step to call the bind answer method on them on continue
    const answerElements = ref([]);
    const fader = ref(null);

    onBeforeUpdate(() => {
      console.log("QuestionView.onBeforeUpdate");
      answerElements.value = [];
    });

    onMounted(() => {
      console.log("answerElements[0]", answerElements.value);
    });

    return {
      answerElements,
      fader
    };
  },
  props: {
    question: {
      type: Question,
      required: true,
      default: () =>
        new Question(-1, new DefaultQuestionTextProvider("default"))
    },
    isFirstQuestion: { type: Boolean, required: true }
  },
  data() {
    return {
      selectedAnswerId: null,
      showAnswerRequiredMessage: false,
      answerRequiredMessage: "Please answer this question",
      fadeQuestionOut: false,
      fadeQuestionIn: false,
      showConfirmationMessage: false,
      confirmationMessageText: "Default conf text"
    };
  },
  methods: {
    getAnswerKey: function(answer: Answer) {
      return this.question.id + "-" + answer.id;
    },
    fadeOutQuestion: function() {
      this.fadeQuestionIn = false;
      this.fadeQuestionOut = true;
    },
    fadeInQuestion: function() {
      this.fadeQuestionOut = false;
      this.fadeQuestionIn = true;
    },
    reset: function() {
      console.log("resetting");
      window.scrollTo(0, 0);
      this.selectedAnswerId = null;
      this.showAnswerRequiredMessage = false;
      this.showConfirmationMessage = false;
    },
    onPreviousClicked() {
      this.$emit("previous-clicked", this.question);
    },
    onContinueClicked: function() {
      console.log("answerElements", this.answerElements);

      this.answerElements.forEach(element => {
        element?.bindAnswer();
      });

      if (!this.question.hasValidAnswer()) {
        const questionPresentationType = this.question.getAnswerPresentationType();
        if (questionPresentationType == AnswerPresentationType.Radio) {
          this.answerRequiredMessage = "Please answer this question";
          this.showAnswerRequiredMessage = true;
          return;
        }

        if (this.question.hasCustomValidator()) {
          this.answerRequiredMessage = this.question.getCustomValidationMessage();
          this.showAnswerRequiredMessage = true;
          return;
        }

        this.answerElements.forEach(ae => ae?.highlightInvalidAnswer());
        return;
      }

      if (
        this.question.confirmationBehaviour.IsConfirmationRequired(
          this.question
        )
      ) {
        this.showConfirmationMessage = true;
        this.confirmationMessageText = this.question.confirmationBehaviour.GetConfirmationText(
          this.question
        );
        return;
      }

      this.onAnswered();
    },
    onAnswerRejectedClicked() {
      this.showConfirmationMessage = false;
    },
    onAnswerConfirmedClicked() {
      this.onAnswered();
    },
    onAnswered() {
      this.$emit("answered", this.question);
    },
    onRadioChange(event: any) {
      this.selectedAnswerId = event.target.value;
      this.showAnswerRequiredMessage = false;
    },
    showNextQuestion(question: Question) {
      console.log("next question", question);
    }
  },
  computed: {
    navButtonsPreviousText() {
      return this.isFirstQuestion ? "Back" : "Change my previous answer";
    },
    getProgressText() {
      const totalQuestions = 20;
      let displayNumber = totalQuestions - this.question.id;

      if (displayNumber >= 5) {
        // Fix for transfer value too low placeholder.
        displayNumber -= 1;
      }

      return `Question ${displayNumber} of a possible ${totalQuestions - 1}`;
    }
  }
});
