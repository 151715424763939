import AnswerValidationResponse from "./answer-validation-response";

type UserAnswerValidator = (userAnswer: any) => boolean;

class AnswerValidator {
  validator: UserAnswerValidator;
  invalidReason: string;

  public constructor(validator: UserAnswerValidator, invalidReason: string) {
    if (!validator) {
      throw new Error("validator cannot be null");
    }

    this.validator = validator;
    this.invalidReason = invalidReason;
  }

  public Validate(userAnswer: any): AnswerValidationResponse {
    const isValid = this.validator(userAnswer);
    if (isValid) {
      return AnswerValidationResponse.Valid();
    }

    return new AnswerValidationResponse(this.invalidReason);
  }
}

export default AnswerValidator;
