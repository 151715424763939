
import { defineComponent } from "vue";
import ReviewAnswers from "@/db/components/ReviewAnswers.vue";
import NavButtons from "@/db/components/NavButtons.vue";
import ServerOutcome from "../ts/outcome";
import NoOutcome from "@/db/components/outcomes/NoOutcome.vue";
import YesOutcome from "@/db/components/outcomes/YesOutcome.vue";
import GuiideAdvisors from "@/db/components/outcomes/GuiideAdvisors.vue";
import OrigenAdvisors from "@/db/components/outcomes/OrigenAdvisors.vue";

enum OutcomeState {
  Outcome,
  AnswerReview,
  Advisors
}

export default defineComponent({
  name: "Outcome",
  components: {
    ReviewAnswers,
    NavButtons,
    YesOutcome,
    NoOutcome,
    GuiideAdvisors,
    OrigenAdvisors
  },
  data() {
    return {
      currentView: OutcomeState.Outcome,
      forceOtherOutcomeEngaged: false,
      forceOtherOutcomeAsYes: false
    };
  },
  methods: {
    onNextClicked: function() {
      this.currentView = OutcomeState.Advisors;
    },
    onReviewAnswersClicked: function() {
      this.currentView = OutcomeState.AnswerReview;
    },
    onReturnToOutcomeClicked: function() {
      this.currentView = OutcomeState.Outcome;
    },
    forceOtherOutcome: function() {
      let forceAsYes = false;
      if (this.forceOtherOutcomeEngaged) {
        forceAsYes = !this.forceOtherOutcomeAsYes;
      } else {
        forceAsYes = !this.showYesOutcome;
      }

      this.forceOtherOutcomeEngaged = true;
      this.forceOtherOutcomeAsYes = forceAsYes;
    },
  },
  computed: {
    showYesOutcome: function(): boolean {
      if (this.forceOtherOutcomeEngaged) {
        return this.forceOtherOutcomeAsYes;
      }
      return this.$store.state.serverOutcome == ServerOutcome.ShownAdvisors;
    },
    showOutcomeArea: function() {
      return this.currentView === OutcomeState.Outcome;
    },
    showAdvisorsArea: function() {
      return this.currentView === OutcomeState.Advisors;
    },
    showAnswersArea: function() {
      return this.currentView == OutcomeState.AnswerReview;
    },
    showGuiide: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "";
    },
    isOrigen: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "origen";
    },
  }
});
