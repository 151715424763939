
import NavButtons from "@/db/components/NavButtons.vue";

export default {
  name: "YesOutcome",
  emits: ["show-transfer-advisors-required"],
  components: {
    NavButtons,
  },
  computed: {
    showGuiide: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "";
    },
    showOrigen: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "origen";
    }
  },
};
