
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavButtons",
  emits: ["next-clicked", "previous-clicked"],
  props: {
    previousVisible: { type: Boolean, default: true },
    previousEnabled: { type: Boolean, default: true },
    previousText: { type: String, default: "Back" },
    previousMuted: { type: Boolean, default: true },
    previousShowArrow: { type: Boolean, default: false },
    nextVisible: { type: Boolean, default: true },
    nextEnabled: { type: Boolean, default: true },
    nextText: { type: String, default: "Next" },
    nextMuted: { type: Boolean, default: false },
    nextShowArrow: { type: Boolean, default: true }
  },
  methods: {
    getButtonClass(isMuted: boolean): string {
      return isMuted
        ? "btn btn-text text-muted text-decoration-none"
        : "btn btn-primary mb-2";
    }
  }
});
