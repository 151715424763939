import Temporal from "@/shared/ts/temporal";
import { Answer, AnswerFollowupAction, Question } from "../db-core";
import ServerOutcome from "../outcome";
import { FollowUp, TerminationFollowUp } from "./FollowUp";

abstract class FollowUpQuestionEvaluator {
  public abstract getFollowUp(answer: Answer): FollowUp;
}

class DefaultFollowUpQuestionEvaluator extends FollowUpQuestionEvaluator {
  private answerFollupAction: AnswerFollowupAction;
  private question: Question;

  constructor(answerFollupAction: AnswerFollowupAction, question: Question) {
    super();
    this.answerFollupAction = answerFollupAction;
    this.question = question;
  }

  public getFollowUp(answer: Answer): FollowUp {
    return new FollowUp(this.answerFollupAction, this.question);
  }
}

class BlockedInQuestionsFollowUpQuestionEvaluator extends FollowUpQuestionEvaluator {
  public question: Question;

  constructor(question: Question) {
    super();
    this.question = question;
  }

  public getFollowUp(answer: Answer): FollowUp {
    return new TerminationFollowUp(
      this.question,
      ServerOutcome.BlockedInQuestions
    );
  }
}

class TransferValueFollowUpQuestionEvaluator extends FollowUpQuestionEvaluator {
  private receivedIllustrationQuestion: Question;
  private transferValueTooLow: Question;

  public static MinTransferValue = 30000;

  constructor(
    receivedIllustrationQuestion: Question,
    transferValueTooLow: Question
  ) {
    super();
    this.receivedIllustrationQuestion = receivedIllustrationQuestion;
    this.transferValueTooLow = transferValueTooLow;
  }

  public getFollowUp(answer: Answer): FollowUp {
    if (
      answer == null ||
      !answer.responseGiven ||
      !answer.isValidResponse().isValid
    ) {
      return null;
    }

    if (
      answer.response < TransferValueFollowUpQuestionEvaluator.MinTransferValue
    ) {
      return new TerminationFollowUp(
        this.transferValueTooLow,
        ServerOutcome.TransferValueTooLow
      );
    }

    return new FollowUp(
      AnswerFollowupAction.NextQuestion,
      this.receivedIllustrationQuestion
    );
  }
}

class DateOfBirthFollowUpQuestionEvaluator extends FollowUpQuestionEvaluator {
  private nextSixMonthsQuestion: Question;
  private illHealthQuestion: Question;

  constructor(nextSixMonthsQuestion: Question, illHealthQuestion: Question) {
    super();
    this.nextSixMonthsQuestion = nextSixMonthsQuestion;
    this.illHealthQuestion = illHealthQuestion;
  }

  public getFollowUp(answer: Answer): FollowUp {
    const age = Temporal.calcAge(answer.response);
    const nextQuestion =
      age >= 54.5 ? this.nextSixMonthsQuestion : this.illHealthQuestion;
    return new FollowUp(AnswerFollowupAction.NextQuestion, nextQuestion);
  }
}

export {
  FollowUpQuestionEvaluator,
  DefaultFollowUpQuestionEvaluator,
  BlockedInQuestionsFollowUpQuestionEvaluator,
  TransferValueFollowUpQuestionEvaluator,
  DateOfBirthFollowUpQuestionEvaluator
};
