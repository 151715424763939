
import { StatePensionData } from "@/db/ts/api/statePensionData";
import Pot from "@/db/ts/assets/pot";
import { StoredAnswer } from "@/db/ts/storedAnswer";
import { defineComponent } from "vue";
import { questions } from "../ts/db-questions";
import { formatter } from "../ts/formatter";

export default defineComponent({
  name: "ReviewAnswers",
  data() {
    return {
      formatter: formatter
    };
  },
  methods: {
    getQuestionText: function(questionId: number): string {
      const question = questions.all.filter(q => q.id == questionId)[0];
      console.log("question", question);

      try {
        return question.getText();
      } catch (error) {
        if (questionId == 5) {
          return "What starting income would you like?";
        }

        return "failed to get text for question id " + questionId;
      }
    },
    getMultipartAnswerPrefix(questionId: number, elementIndex: number): string {
      if (questionId == questions.pensionAmountsQuestion.id) {
        switch (elementIndex) {
          case 0:
            return "Full Pension: ";
          case 1:
            return "Reduced Pension: ";
          default:
            return "Cash Amount: ";
        }
      }

      return "";
    }
  },
  computed: {
    storedAnswers(): Array<StoredAnswer> {
      return this.$store.state.serverAnswers;
    },
    storedPots(): Array<Pot> {
      return this.$store.state.serverPots;
    },
    storedStatePension(): StatePensionData {
      return this.$store.state.serverStatePension;
    },
    userId(): string {
      return this.$store.state.userId;
    }
  }
});
