
import { questions } from "../../ts/db-questions";
import { Advisor, advisors } from "../../ts/advisor";

export default {
  name: "OrigenAdvisors",
  emits: ["show-retirement-advisors-required"],
  props: {
    showYesOutcome: { default: false, required: true },
  },
  computed: {
    headerText: function (): string {
      return this.showYesOutcome
        ? "Continue with Transfer Advice"
        : "General retirement advice";
    },
    applicableAdvisors: function (): Array<Advisor> {
      const transferValue = questions.getTransferValue();
      const applicableAdvisors = new Array<Advisor>();
      const showTransferAdvisors: boolean = this.showYesOutcome;
      const filterOnName = this.$store.state.configuration.filterAdvisors;

      if (filterOnName) {
        const filterString = this.$store.state.configuration.advisorFilter;
        advisors.all
          .filter((a) => a.contact.search(filterString) !== -1)
          .forEach((advisor) => {
            applicableAdvisors.push(advisor);
          });
      } else {
        let advisorsOfCorrectType: Array<Advisor>;

        if (showTransferAdvisors) {
          advisorsOfCorrectType = advisors.all.filter(
            (a) => a.isTransferAdvisor && transferValue >= a.minTransferValue
          );
        } else {
          advisorsOfCorrectType = advisors.all.filter(
            (a) => a.isGeneralAdvisor
          );
        }

        const dontPresentLowerPriorityAdvisors =
          advisorsOfCorrectType.length > 3;
        advisorsOfCorrectType.forEach((a) => {
          if (dontPresentLowerPriorityAdvisors && a.lowerPriority) {
            return;
          }
          applicableAdvisors.push(a);
        });
      }

      this.shuffleArray(applicableAdvisors);
      return applicableAdvisors;
    },
  },
  methods: {
    getAdvisorDescription: function (advisor: Advisor): string[] {
      return this.showYesOutcome
        ? advisor.transferAdvisorDescription
        : advisor.generalAdvisorDescription;
    },
    shuffleArray: function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
  },
};
