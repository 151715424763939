import { AnswerFollowupAction, Question } from "../db-core";
import ServerOutcome from "../outcome";

class FollowUp {
  public followUpAction: AnswerFollowupAction;
  public question: Question;

  constructor(followUpAction: AnswerFollowupAction, question: Question) {
    this.followUpAction = followUpAction;
    this.question = question;
  }
}

class TerminationFollowUp extends FollowUp {
  public outcome: ServerOutcome;

  constructor(question: Question, outcome: ServerOutcome) {
    super(AnswerFollowupAction.UserBlocked, question);
    this.outcome = outcome;
  }
}

export { FollowUp, TerminationFollowUp };
