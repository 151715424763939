class RiskConstants {
  public readonly No: string = "NoRisk";

  public readonly Yes: string = "RiskOk";
}

class Constants {
  constructor() {
    this.Risk = new RiskConstants();
  }

  public readonly Risk: RiskConstants;
}

const constants = new Constants();

export { constants };
