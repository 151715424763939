
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "YesNoQuestion",
  props: {
    name: { type: String, required: true },
    text: { type: String, required: true }
  },
  emits: ["answer-changed"],
  setup() {
    const yesAnswer = ref(null);
    const noAnswer = ref(null);

    return {
      yesAnswer,
      noAnswer
    };
  },
  methods: {
    setSelectedAnswer(answeredYes: boolean) {
      console.log("setSelectedAnswer", answeredYes);
      this.yesAnswer.checked = answeredYes;
      this.noAnswer.checked = !answeredYes;
    },
    answeredYes(): boolean {
      console.log("yes radio", this.yesAnswer.checked);
      return this.yesAnswer.checked;
    },
    onNoClick() {
      if (this.answeredYes()) {
        this.noAnswer.checked = true;
        this.onChanged();
      }
    },
    onYesClick() {
      if (!this.answeredYes()) {
        this.yesAnswer.checked = true;
        this.onChanged();
      }
    },
    onChanged() {
      this.$emit("answer-changed");
    }
  }
});
