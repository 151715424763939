import { Question } from "./db-core";
import { Questions } from "./db-questions";
import store from "@/db/store/index";

class WorkflowEventHandler {
  private questions: Questions;

  public constructor(questions: Questions) {
    this.questions = questions;
  }

  public QuestionAnswered(answered: Question) {
    if (answered.id === this.questions.sexQuestion.id) {
      this.AllInformationRequiredForStatePensionCalculationGiven();
    }

    if (answered.id == this.questions.currentSchemeInflation.id) {
      this.AllInformationRequiredForReducedPensionCalculationGiven();
    }
  }

  private AllInformationRequiredForStatePensionCalculationGiven() {
    const request = {
      dateOfBirth: this.questions.getDateOfBirthAnswer(),
      sex: this.questions.getSexAnswer()
    };
    store.dispatch("getStatePensionDetail", request);
  }

  private AllInformationRequiredForReducedPensionCalculationGiven() {
    store.dispatch("getReducedPensionValues");
  }
}

export default WorkflowEventHandler;
