
import { defineComponent } from "vue";
import YouTube from "vue3-youtube";
import { Configuration } from "../ts/configuration";

export default defineComponent({
  name: "EducationalVideo",
  components: {
    YouTube
  },
  data() {
    return {
      videoHasEnded: false,
      playerVars: {
        autoplay: 0,
        controls: 0,
        disablekb: 1,
        modestbranding: 1,
        rel: 0
      }
    };
  },
  computed: {
    videoUrl: function(): string {
      const config: Configuration = this.$store.state.configuration;
      return config.videoUrl;
    }
  },
  methods: {
    onStateChanged(payload) {
      if (payload.data == 0) {
        this.videoHasEnded = true;
      }
    },
    onSkipVideoClicked() {
      this.emitNext();
    },
    onContinueClicked() {
      this.emitNext();
    },
    emitNext() {
      this.$emit("nextClicked");
    }
  }
});
