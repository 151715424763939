
import { defineComponent } from "vue";

export default defineComponent({
  name: "Philosophy",
  methods: {
    onNextClicked() {
      this.$emit("nextClicked");
    }
  }
});
