import {
  Question,
  Answer,
  AnswerFollowupAction,
  QuestionTextProvider,
  DefaultQuestionTextProvider,
  CustomQuestionValidator
} from "./db-core";
import AnswerPresentationType from "./answer-presentation-type";
import AnswerValidator from "./answer-validator";
import WorkflowEventHandler from "./workflowEventHandler";
import { formatter } from "./formatter";
import { constants } from "./constants";
import {
  BlockedInQuestionsFollowUpQuestionEvaluator,
  DefaultFollowUpQuestionEvaluator,
  FollowUpQuestionEvaluator,
  TransferValueFollowUpQuestionEvaluator,
  DateOfBirthFollowUpQuestionEvaluator
} from "./questions/FollowUpQuestionEvaluator";
import { TranValueConfirmationBehaviour } from "./tv-confirmation-behaviour";
import Temporal from "@/shared/ts/temporal";

const userBlockedInQuestions = new Question(
  -1,
  new DefaultQuestionTextProvider("UserBlockedInQuestions")
);
const userHasCompletedQuestions = new Question(
  -1,
  new DefaultQuestionTextProvider("UserHasCompletedQuestions")
);

class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

class Option {
  public Label: string;
  public Value: string;
  public followUp: Question;

  public constructor(label: string, followUp: Question, value: string = null) {
    this.Label = label;
    this.followUp = followUp;
    this.Value = value ?? label;
  }
}

class QuestionFactory {
  private id = 0;
  public all = new Array<Question>();

  statement(text: string) {
    const question = new Question(
      this.id,
      new DefaultQuestionTextProvider(text)
    );

    this.incrementQuestionId();

    return question;
  }

  createAnswer(
    id: number,
    label: string,
    followUpQuestionEvaluator: FollowUpQuestionEvaluator,
    presentationType = AnswerPresentationType.Radio,
    answerValidators: Array<AnswerValidator> = null,
    delegatedResponse: string = null,
    response: any = null
  ): Answer {
    return new Answer(
      id,
      label,
      followUpQuestionEvaluator,
      presentationType,
      answerValidators,
      delegatedResponse,
      response
    );
  }

  getFollowUp(nextQuestion: Question): FollowUpQuestionEvaluator {
    if (nextQuestion === userBlockedInQuestions) {
      return new BlockedInQuestionsFollowUpQuestionEvaluator(null);
    } else if (nextQuestion == userHasCompletedQuestions) {
      return new DefaultFollowUpQuestionEvaluator(
        AnswerFollowupAction.QuestionsCompleted,
        null
      );
    } else {
      return new DefaultFollowUpQuestionEvaluator(
        AnswerFollowupAction.NextQuestion,
        nextQuestion
      );
    }
  }

  yesNoQuestion(
    text: string,
    yesFollowup?: Question,
    noFollowup?: Question,
    isKeyQuestion = false
  ) {
    const question = new Question(
      this.id,
      new DefaultQuestionTextProvider(text),
      isKeyQuestion
    );

    const yesFollowUpEvaluator = this.getFollowUp(yesFollowup);
    const yesAnswer = this.createAnswer(
      0,
      "Yes",
      yesFollowUpEvaluator,
      AnswerPresentationType.Radio,
      null,
      "Y"
    );
    question.addAnswer(yesAnswer);

    const noFollowUpEvaluator = this.getFollowUp(noFollowup);
    const noAnswer = this.createAnswer(
      1,
      "No",
      noFollowUpEvaluator,
      AnswerPresentationType.Radio,
      null,
      "N"
    );
    question.addAnswer(noAnswer);

    this.registerQuestion(question);

    return question;
  }

  optionsQuestion(
    questionTextProvider: QuestionTextProvider,
    options: Array<Option>,
    isKeyQuestion = false
  ) {
    const question = new Question(this.id, questionTextProvider, isKeyQuestion);

    options.forEach((o, i) => {
      const followUp = this.getFollowUp(o.followUp);

      const answer = this.createAnswer(
        i,
        o.Label,
        followUp,
        AnswerPresentationType.Radio,
        null,
        o.Value
      );
      question.addAnswer(answer);
    });

    this.registerQuestion(question);

    return question;
  }

  valuesQuestion(
    text: string,
    labels: Array<string>,
    followup?: Question,
    isKeyQuestion = false,
    customQuestionValidator: CustomQuestionValidator = null
  ) {
    const question = new Question(
      this.id,
      new DefaultQuestionTextProvider(text),
      isKeyQuestion,
      true,
      customQuestionValidator
    );
    labels.forEach((l, i) => {
      const followupQuestionEvaluator = this.getFollowUp(followup);
      question.addAnswer(
        new Answer(i, l, followupQuestionEvaluator, AnswerPresentationType.Text)
      );
    });

    this.registerQuestion(question);

    console.log(question, "valuesQuestion");

    return question;
  }

  valueQuestion(
    textProvider: QuestionTextProvider,
    label: string,
    followupQuestionEvaluator: FollowUpQuestionEvaluator,
    isKeyQuestion = false,
    answerPresentationType: AnswerPresentationType = AnswerPresentationType.Text,
    validators: Array<AnswerValidator> = null,
    response: any = null,
    canGoBack = true
  ) {
    const question = new Question(
      this.id,
      textProvider,
      isKeyQuestion,
      canGoBack
    );

    const answer = this.createAnswer(
      0,
      label,
      followupQuestionEvaluator,
      answerPresentationType,
      validators,
      null,
      response
    );

    question.addAnswer(answer);

    this.registerQuestion(question);

    return question;
  }

  private registerQuestion(question: Question) {
    this.incrementQuestionId();
    question.guid = Guid.newGuid();
    this.all.push(question);
  }

  private incrementQuestionId() {
    this.id += 1;
  }
}

class Questions {
  public all: Array<Question>;
  public firstQuestion: Question;
  public dateOfBirthQuestion: Question;
  public illHealthQuestion: Question;
  public transferValueAmountQuestion: Question;
  public sexQuestion: Question;
  public lifeExpectancyQuestion: Question;
  public estimatedPensionStatement: Question;
  public pensionAmountsQuestion: Question;
  public currentSchemeInflation: Question;
  public startTakingPensionSoon: Question;
  public youngAndInGoodHealth: Question;
  public retireInLondon: Question;
  public lifestyle: Question;
  public income: Question;
  public incomeFlexibility: Question;
  public howManyYearsWorking: Question;
  public hasShortTermDebt: Question;
  public howMuchDebt: Question;
  public choiceOfIncome: Question;
  public viewOnRisk: Question;
  public receivedIllustration: Question;
  public shortTermDebt: Question;

  constructor(allQuestions: Array<Question>) {
    this.all = allQuestions;
  }

  public getAnswers(question: Question): string[] {
    const answer = [];

    if (question.id === this.startTakingPensionSoon.id) {
      answer.push(String(this.getBooleanResponseFromQuestion(question, false)));
    } else if (question.id === this.dateOfBirthQuestion.id) {
      answer.push(String(this.getDateOfBirthAnswer()));
    } else if (question.id === this.illHealthQuestion.id) {
      answer.push(String(this.getIllHealthQuestionAnswer()));
    } else if (question.id === this.sexQuestion.id) {
      answer.push(this.getSexAnswer());
    } else if (question.id === this.shortTermDebt.id) {
      answer.push(String(this.getBooleanResponseFromQuestion(question, false)));
    } else if (question.id === this.lifeExpectancyQuestion.id) {
      answer.push(String(this.getHasShortenedLifeExpectancyAnswer()));
    } else if (question.id === this.receivedIllustration.id) {
      answer.push(String(this.getHasReceivedIllustration()));
    } else if (question.id === this.transferValueAmountQuestion.id) {
      answer.push(String(this.getTransferValue()));
    } else if (question.id === this.estimatedPensionStatement.id) {
      answer.push("Ok");
    } else if (question.id === this.pensionAmountsQuestion.id) {
      answer.push(String(this.getFullPension()));
      answer.push(String(this.getReducedPension()));
      answer.push(String(this.getReducedPensionCashAmount()));
    } else if (question.id === this.currentSchemeInflation.id) {
      answer.push(this.currentSchemeInflation.getSelectedAnswer().label);
    } else if (question.id === this.retireInLondon.id) {
      answer.push(String(this.getWillRetireInLondon()));
    } else if (question.id === this.lifestyle.id) {
      answer.push(this.getDesiredLifeStyle());
    } else if (question.id === this.income.id) {
      answer.push(String(this.getIncome()));
    } else if (question.id === this.incomeFlexibility.id) {
      answer.push(String(this.getIncomeFlexibility()));
    } else if (question.id === this.howManyYearsWorking.id) {
      answer.push(this.howManyYearsWorking.getSelectedAnswer().label);
    } else if (question.id === this.howMuchDebt.id) {
      answer.push(String(this.getDebt()));
    } else if (question.id === this.choiceOfIncome.id) {
      answer.push(this.getChoiceOfIncome());
    } else if (question.id === this.viewOnRisk.id) {
      answer.push(this.viewOnRisk.getSelectedAnswer().label);
    } else {
      answer.push(`Error, question not mapped: ${question.getText()}`);
    }

    return answer;
  }

  public getDateOfBirthAnswer(): Date {
    return this.dateOfBirthQuestion.answers[0].response;
  }

  public getIllHealthQuestionAnswer(): boolean {
    return this.getBooleanResponseFromQuestion(this.illHealthQuestion, false);
  }

  public getSexAnswer(): string {
    return this.sexQuestion.getSelectedAnswer().response;
  }

  public getHasShortenedLifeExpectancyAnswer(): boolean {
    return this.getBooleanResponseFromQuestion(
      this.lifeExpectancyQuestion,
      false
    );
  }

  public getTransferValue(): number {
    return this.transferValueAmountQuestion.answers[0].response;
  }

  public getHasReceivedIllustration(): boolean {
    return this.getBooleanResponseFromQuestion(this.receivedIllustration, true);
  }

  public getDeclaredOrEstimatedFullPension(): number {
    const hasReceivedIllustration = this.getHasReceivedIllustration();
    let fullPensionValue = 0;

    if (hasReceivedIllustration) {
      fullPensionValue = this.getFullPension();
    } else {
      const transferValue = this.getTransferValue();
      fullPensionValue = Math.round(transferValue / 30);
    }

    return fullPensionValue;
  }

  public getFullPension(): number | null {
    if (this.pensionAmountsQuestion.answers[0].response === "") {
      return null;
    }

    return this.pensionAmountsQuestion.answers[0].response;
  }

  public getReducedPension(): number | null {
    if (this.pensionAmountsQuestion.answers[1].response === "") {
      return null;
    }

    return this.pensionAmountsQuestion.answers[1].response;
  }

  public getReducedPensionCashAmount(): number | null {
    if (this.pensionAmountsQuestion.answers[2].response === "") {
      return null;
    }

    return this.pensionAmountsQuestion.answers[2].response;
  }

  public getCurrentSchemeInflation(): number {
    return this.currentSchemeInflation.getSelectedAnswer().response;
  }

  public getWillRetireInLondon(): boolean {
    return this.getBooleanResponseFromQuestion(this.retireInLondon, false);
  }

  public getDesiredLifeStyle(): string {
    return this.lifestyle.getSelectedAnswer().response;
  }

  public getIncome(): number {
    return this.income.getSelectedAnswer().response;
  }

  public getIncomeFlexibility(): boolean {
    return this.getBooleanResponseFromQuestion(this.incomeFlexibility, true);
  }

  public getHowManyYearsWorking(): number {
    return this.howManyYearsWorking.getSelectedAnswer().response;
  }

  public getDebt(): number {
    if (!this.howMuchDebt.hasValidAnswer()) {
      return 0;
    }
    return this.howMuchDebt.getSelectedAnswer().response;
  }

  public getChoiceOfIncome(): string {
    return this.choiceOfIncome.getSelectedAnswer().response;
  }

  public getViewOnRisk(): string {
    return this.viewOnRisk.getSelectedAnswer().response;
  }

  private getBooleanResponseFromQuestion(
    question: Question,
    defaultAnswer: boolean
  ): boolean {
    if (!question.hasValidAnswer()) {
      return defaultAnswer;
    }
    const answer = question.getSelectedAnswer();
    const booleanResponse = answer.getBooleanResponse();
    return booleanResponse == null ? false : booleanResponse;
  }
}

const questionFactory = new QuestionFactory();
const questions = new Questions(questionFactory.all);

const viewOnRiskTextProvider = new DefaultQuestionTextProvider(
  "Which of the following statements do you think best sums up how you take risk with your money?"
);
const viewOnRisk = questionFactory.optionsQuestion(viewOnRiskTextProvider, [
  new Option(
    "I want to know my money can never fall in value, so I only ever invest in something which I know is risk free, like a bank savings account.",
    userHasCompletedQuestions,
    constants.Risk.No
  ),
  new Option(
    "I don’t mind taking at least some risk. I know the value of any investment may go down as well as up and I am comfortable taking this risk.",
    userHasCompletedQuestions,
    constants.Risk.Yes
  )
]);

const howManyYearsWorking = questionFactory.optionsQuestion(
  new DefaultQuestionTextProvider(
    "Your State Pension depends on the number of years you have been paying National Insurance Contributions, either through working" +
      " or claiming benefits, for children, caring or unemployment.<br><br>" +
      "How many years, in total, do you think you have been doing at least one of these?<br><br>" +
      "<a href='https://guiidewordpress.azurewebsites.net/dont-sleepwalk-into-a-low-state-pension' target='_blank'>For more detail on the State Pension see here</a>"
  ),
  [
    new Option("Less than 10", viewOnRisk, "0"),
    new Option("Between 10 and 20", viewOnRisk, "15"),
    new Option("Between 20 and 30", viewOnRisk, "25"),
    new Option("30 or more", viewOnRisk, "35"),
    new Option("Not sure", viewOnRisk, "25")
  ],
  true
);

const incomeFlexibility = questionFactory.yesNoQuestion(
  "Do you want flexibility in the income you can take from year to year, like being able to take more cash at the start, or different (rather than fixed) amounts of income in each year?",
  howManyYearsWorking,
  howManyYearsWorking
);

const choiceOfIncomeTextProvider = new DefaultQuestionTextProvider(
  "An income that increases will help protect against the cost of what you buy going up over time (inflation)." +
    "<br><br>" +
    "With a flat income you can get a higher starting amount with the same size pot, but it will buy less in later years." +
    "<br><br>" +
    "Given this, do you want a flat income, or one which increases?"
);
const choiceOfIncome = questionFactory.optionsQuestion(
  choiceOfIncomeTextProvider,
  [
    new Option("Flat", incomeFlexibility, "Flat"),
    new Option("Increasing", incomeFlexibility, "Increasing")
  ]
);

class TargetIncomeCalculator {
  baseIncomeAmounts: { [id: string]: number };

  constructor() {
    this.baseIncomeAmounts = {};
    this.configureBaseIncomeAmounts();
  }

  public calcSuggestedRetirementIncome(
    livesInLondon: boolean,
    supportingPartner: boolean,
    lifeStyle: string
  ): number {
    const key =
      (livesInLondon ? "Y" : "N") +
      (supportingPartner ? "Y" : "N") +
      lifeStyle.substring(0, 2).toUpperCase();
    const baseAmount = this.baseIncomeAmounts[key];
    const adjustedAmount =
      Math.round(this.applyInflation(baseAmount) / 100) * 100;
    return adjustedAmount;
  }

  private applyInflation(value): number {
    const yearTillRetirement = 0.5;
    const inflationRate = 2.5;
    const inflationIncreaseRate = 1 + inflationRate / 100;
    return value * Math.pow(inflationIncreaseRate, yearTillRetirement);
  }

  private configureBaseIncomeAmounts() {
      this.baseIncomeAmounts["NNMI"] = 12800;
      this.baseIncomeAmounts["NNMO"] = 23300;
      this.baseIncomeAmounts["NNCO"] = 37300;
      this.baseIncomeAmounts["YNMI"] = 15800;
      this.baseIncomeAmounts["YNMO"] = 28300;
      this.baseIncomeAmounts["YNCO"] = 40900;
      this.baseIncomeAmounts["NYMI"] = 19900;
      this.baseIncomeAmounts["NYMO"] = 34000;
      this.baseIncomeAmounts["NYCO"] = 54500;
      this.baseIncomeAmounts["YYMI"] = 25700;
      this.baseIncomeAmounts["YYMO"] = 41400;
      this.baseIncomeAmounts["YYCO"] = 56500;
  }
}

class IncomeQuestionTextProvider extends QuestionTextProvider {
  private questions: Questions;

  constructor(questions: Questions) {
    super();
    this.questions = questions;
  }

  public getText(question: Question): string {
    const targetIncomeCalculator = new TargetIncomeCalculator();
    const willRetireInLondon = this.questions.getWillRetireInLondon();
    const isSupportingPartner = false;
    const getDesiredLifeStyle = this.questions.getDesiredLifeStyle();
    const suggestedIncome = targetIncomeCalculator.calcSuggestedRetirementIncome(
      willRetireInLondon,
      isSupportingPartner,
      getDesiredLifeStyle
    );

    const formattedIncome = formatter.formatCurrency(suggestedIncome);
    return (
      `Based on your answers, using those living standards, a starting income of ${formattedIncome} a year after tax would seem a good target.` +
      "<br><br>" +
      `You can always change this a bit below if you wish to.`
    );
  }
}

const incomeQuestionTextProvider = new IncomeQuestionTextProvider(questions);

const income = questionFactory.valueQuestion(
  incomeQuestionTextProvider,
  "Income",
  new DefaultFollowUpQuestionEvaluator(
    AnswerFollowupAction.NextQuestion,
    choiceOfIncome
  )
);

const standardsLink =
  "<a href='https://www.retirementlivingstandards.org.uk' target='_blank'>See more details of what these standards mean for your type of lifestyle in retirement</a>";
const lifeStyleTextProvider = new DefaultQuestionTextProvider(
  `What type of lifestyle do you want to aim for in retirement - minimum, moderate, comfortable?` +
    "<br><br>Minimum would cover all your needs but leave little left over for fun. Moderate would give more financial security and flexibility. " +
    "Comfortable means more financial freedom and some luxuries." +
    "<br><br>" +
    `${standardsLink}` +
    "."
);
const lifestyle = questionFactory.optionsQuestion(lifeStyleTextProvider, [
  new Option("Minimum", income),
  new Option("Moderate", income),
  new Option("Comfortable", income)
]);

const retireInLondon = questionFactory.yesNoQuestion(
  "Do you plan to retire in London?",
  lifestyle,
  lifestyle
);

const howMuchDebt = questionFactory.valueQuestion(
  new DefaultQuestionTextProvider("Roughly what size are these?"),
  "Value",
  new DefaultFollowUpQuestionEvaluator(
    AnswerFollowupAction.NextQuestion,
    retireInLondon
  )
);

const shortTermDebtText =
  "Do you have any large debts (credit cards with high interest etc) causing you financial hardship which you would you like to pay off immediately when you retire?";
const shortTermDebt = questionFactory.yesNoQuestion(
  shortTermDebtText,
  howMuchDebt,
  retireInLondon
);

const lifeExpectancyText =
  "Do you have any serious health issues which could reduce your life expectancy to less than 5 years?";
const lifeExpectancy = questionFactory.yesNoQuestion(
  lifeExpectancyText,
  shortTermDebt,
  shortTermDebt
);

const sex = questionFactory.optionsQuestion(
  new DefaultQuestionTextProvider("Are you male or female?"),
  [
    new Option("Male", lifeExpectancy, "M"),
    new Option("Female", lifeExpectancy, "F")
  ],
  true
);

const currentSchemeInflation = questionFactory.optionsQuestion(
  new DefaultQuestionTextProvider(
    "When you retire, will your scheme pension increase each year or stay the same?"
  ),
  [new Option("Increase", sex, "2.5"), new Option("Stay the same", sex, "0")]
);

const estimatedPensionStatement = questionFactory.optionsQuestion(
  new DefaultQuestionTextProvider(
    "We will estimate the pension based on your transfer value. If you do go on to take advice you will need to get this information as" +
      " soon as possible from your scheme administrator and provide this to your adviser."
  ),
  [new Option("Ok", currentSchemeInflation)]
);

const fullPensionLabel = "Full Pension";
const reducedPensionLabel = "Reduced Pension";
const cashAmountPensionLabel = "Cash Amount";

enum PensionAmountsValidationState {
  Valid,
  NotDefined,
  ReducedAmountDefinedButNoCashAmount,
  ReducedCashDefinedButNoReducedAmount,
  ReducedPensionGreaterThanFullPension
}

class PensionAmountsQuestionValidator extends CustomQuestionValidator {
  public isValid(answers: Array<Answer>): boolean {
    return this.checkState(answers) === PensionAmountsValidationState.Valid;
  }

  public getFailureMessage(answers: Array<Answer>): string {
    const state = this.checkState(answers);

    switch (state) {
      case PensionAmountsValidationState.NotDefined: {
        return "Please enter either the full pension amount or the reduced pension and cash amounts. If available, enter all three.";
      }
      case PensionAmountsValidationState.ReducedAmountDefinedButNoCashAmount: {
        return "Please define the cash value of the reduced pension.";
      }
      case PensionAmountsValidationState.ReducedCashDefinedButNoReducedAmount: {
        return "Please define the pension value of the reduced pension.";
      }
      case PensionAmountsValidationState.ReducedPensionGreaterThanFullPension: {
        return "The reduced pension amount must be less than the full pension amount.";
      }
      default: {
        return "";
      }
    }
  }

  private checkState(answers: Array<Answer>): PensionAmountsValidationState {
    const fullPensionAnswer = answers.filter(
      a => a.label == fullPensionLabel
    )[0];
    const reducedPensionAnswer = answers.filter(
      a => a.label == reducedPensionLabel
    )[0];
    const cashAmountPensionAnswer = answers.filter(
      a => a.label == cashAmountPensionLabel
    )[0];

    const fullPensionAnswerIsValid = fullPensionAnswer.isValidResponse()
      .isValid;
    const reducedPensionAnswerIsValid = reducedPensionAnswer.isValidResponse()
      .isValid;
    const cashAmountPensionAnswerIsValid = cashAmountPensionAnswer.isValidResponse()
      .isValid;

    if (reducedPensionAnswerIsValid && !cashAmountPensionAnswerIsValid) {
      return PensionAmountsValidationState.ReducedAmountDefinedButNoCashAmount;
    }

    if (cashAmountPensionAnswerIsValid && !reducedPensionAnswerIsValid) {
      return PensionAmountsValidationState.ReducedCashDefinedButNoReducedAmount;
    }

    const reducedIsValid =
      reducedPensionAnswerIsValid && cashAmountPensionAnswerIsValid;

    if (fullPensionAnswerIsValid && reducedIsValid) {
      const fullPension: number = parseInt(fullPensionAnswer.response);
      const reducedPension: number = parseInt(reducedPensionAnswer.response);
      if (reducedPension >= fullPension) {
        return PensionAmountsValidationState.ReducedPensionGreaterThanFullPension;
      }
    }

    if (fullPensionAnswerIsValid || reducedIsValid) {
      return PensionAmountsValidationState.Valid;
    }

    return PensionAmountsValidationState.NotDefined;
  }
}

const pensionAmountLabels = [
  fullPensionLabel,
  reducedPensionLabel,
  cashAmountPensionLabel
];
const pensionAmounts = questionFactory.valuesQuestion(
  "You usually get shown two options; a pension amount or a lower pension amount plus some tax free cash." +
    "<br><br>" +
    "If you have both enter both, if not, just enter any one option.",
  pensionAmountLabels,
  currentSchemeInflation,
  true,
  new PensionAmountsQuestionValidator()
);

const receivedIllustration = questionFactory.yesNoQuestion(
  "On your transfer statement, did it also show the scheme pension that will be paid when you plan to retire in a few months time?",
  pensionAmounts,
  estimatedPensionStatement
);

const transferValueTooLowMessage =
  "This statement is never seen as the user is shown the terminus. This acts as a placeholder for a question id.";
const transferValueTooLow = questionFactory.statement(
  transferValueTooLowMessage
);

const transferValueAmount = questionFactory.valueQuestion(
  new DefaultQuestionTextProvider(
    "Please enter your transfer value on your statement provided."
  ),
  "Value",
  new TransferValueFollowUpQuestionEvaluator(
    receivedIllustration,
    transferValueTooLow
  ),
  false,
  AnswerPresentationType.Text,
  null,
  null,
  false
);
transferValueAmount.confirmationBehaviour = new TranValueConfirmationBehaviour();

const illHealthQuestion = questionFactory.yesNoQuestion(
  "Are you in serious ill health, with a life expectancy of less than 5 years?",
  transferValueAmount,
  userBlockedInQuestions
);

const next6Months = questionFactory.yesNoQuestion(
  "Do you want to start taking some cash or an income from your current scheme in the next 12 months?",
  transferValueAmount,
  illHealthQuestion
);

function calcDefaultDobResponse(): Date {
  const now = new Date();
  const yearSixtyYearsAgo = now.getFullYear() - 60;
  return new Date(yearSixtyYearsAgo, now.getMonth(), 1);
}
const dobMaxAgeValidationFunction = (userAnsweredDOB: Date) => {
  const age = Temporal.calcAge(userAnsweredDOB);
  const maxAge = 65;
  return age <= maxAge;
};
const ageValidators = [
  new AnswerValidator(
    dobMaxAgeValidationFunction,
    "You must be under 65 to use this website."
  )
];
const defaultDobResponse = calcDefaultDobResponse();
const dob = questionFactory.valueQuestion(
  new DefaultQuestionTextProvider("What is your date of birth?"),
  "Date",
  new DateOfBirthFollowUpQuestionEvaluator(next6Months, illHealthQuestion),
  true,
  AnswerPresentationType.DatePicker,
  ageValidators,
  defaultDobResponse
);

questions.firstQuestion = dob;
questions.all = questionFactory.all;

questions.dateOfBirthQuestion = dob;
questions.illHealthQuestion = illHealthQuestion;
questions.transferValueAmountQuestion = transferValueAmount;
questions.receivedIllustration = receivedIllustration;
questions.sexQuestion = sex;
questions.shortTermDebt = shortTermDebt;
questions.lifeExpectancyQuestion = lifeExpectancy;
questions.estimatedPensionStatement = estimatedPensionStatement;
questions.pensionAmountsQuestion = pensionAmounts;
questions.currentSchemeInflation = currentSchemeInflation;
questions.startTakingPensionSoon = next6Months;
questions.youngAndInGoodHealth = illHealthQuestion;
questions.lifestyle = lifestyle;
questions.retireInLondon = retireInLondon;
questions.income = income;
questions.howManyYearsWorking = howManyYearsWorking;
questions.hasShortTermDebt = shortTermDebt;
questions.howMuchDebt = howMuchDebt;
questions.choiceOfIncome = choiceOfIncome;
questions.viewOnRisk = viewOnRisk;
questions.incomeFlexibility = incomeFlexibility;

const workflowEventHandler = new WorkflowEventHandler(questions);

export { Questions, questions, workflowEventHandler, TargetIncomeCalculator };
