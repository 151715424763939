import Result from "./result";

export default class DataResult<T> extends Result {
    data: T;

    constructor(successful: boolean, message: string, data: T) {
        super(successful, message);
        this.data = data;
    }
};
