class Formatter {
  private options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long"
  };

  public formatCurrency(amount: number): string {
    const currencyFormatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0
    });

    try {
      return currencyFormatter.format(amount);
    } catch (error) {
      return "";
    }
  }

  public formatCurrencyForInput(amount: number): string {
    const currency = this.formatCurrency(amount);
    return currency.replace("£", "");
  }

  public currencyToNumber(amount: string): number {
    if (amount == "") {
      return 0;
    }

    const amountValue = amount.replace("£", "").replace(",", "");
    return parseInt(amountValue);
  }

  public longDate(date: string | Date): string {
    date = this.ensureDateObj(date);

    const formattedMonthAndYear = date.toLocaleDateString(
      "en-GB",
      this.options
    );
    const day = date.getDate();
    const formattedDay = this.nth(day);
    return `${day}${formattedDay} of ${formattedMonthAndYear}`;
  }

  public standardDate(date: string | Date): string {
    date = this.ensureDateObj(date);

    const dateString = date
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit"
      })
      .replace("Sept", "Sep");

    return dateString.replace(/ /g, "-");
  }

  public monthAndYearDate(date: Date): string {
    date = this.ensureDateObj(date);
    return date.toLocaleDateString("en-GB", this.options);
  }

  private nth(n) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  private ensureDateObj(possibleDate: string | Date): Date {
    if (possibleDate instanceof Date) {
      return possibleDate;
    }
    return new Date(possibleDate);
  }
}

const formatter = new Formatter();

export { formatter };
