class Advisor {
  public name: string;
  public transferAdvisorDescription: string[];
  public generalAdvisorDescription: string[];
  public minTransferValue: number;
  public isTransferAdvisor: boolean;
  public isGeneralAdvisor: boolean;
  public moreDetails: string;
  public contact: string;
  public logo: string;
  public lowerPriority = false;

  constructor(
    name: string,
    transferAdvisorDescription: string[],
    generalAdvisorDescription: string[],
    minTransferValue: number,
    isTransferAdvisor: boolean,
    isGeneralAdvisor: boolean,
    moreDetails: string,
    contact: string,
    logo: string
  ) {
    this.name = name;
    this.transferAdvisorDescription = transferAdvisorDescription;
    this.generalAdvisorDescription = generalAdvisorDescription;
    this.minTransferValue = minTransferValue;
    this.isTransferAdvisor = isTransferAdvisor;
    this.isGeneralAdvisor = isGeneralAdvisor;
    this.moreDetails = moreDetails;
    this.contact = contact;
    this.logo = logo;
  }

  public moreDetailsAvailable(): boolean {
    return this.moreDetails.length > 0;
  }
}

class Advisors {
  public all: Advisor[];

  constructor() {
    this.all = new Array<Advisor>();
  }
}

const advisors = new Advisors();

const hubfsDesc = new Array<string>();
hubfsDesc.push("HUB Pension Consulting are part of Just Group plc, an award-winning FTSE listed company that focuses on providing products and services to people approaching or in retirement.");
hubfsDesc.push("Their pension advice service helps you to understand the benefits of your defined benefit (final salary) pension, and the alternative options available to you through a pension transfer. Their two-tier service means you won’t need to pay a fee unless you decide to proceed with full advice.");
const hubfs = new Advisor(
  "Hub FS",
  hubfsDesc,
  hubfsDesc,
  0,
  true,
  false,
  "https://www.hubpensionconsulting.co.uk/globalassets/hubpensionconsulting/guiide/db_pension_advice_leaflet_guiide.pdf",
  "https://www.hubpensionconsulting.co.uk/advice-services/final-salary-pension-advice",
  "hub-fs-logo.png"
);

const origenDesc = new Array<string>();
origenDesc.push(
  "Origen Financial Services is one of the UK's leading national financial advisers and has been awarded 'Retirement Planning Advisory Firm of the Year' for the last eight years."
);
origenDesc.push(
  "Their personal advice service includes a dedicated adviser who will take the time to understand your financial situation, explain your options without using jargon, and help you to maximise your retirement income."
);
const origenAdvisor = new Advisor(
  "Origen Financial Services",
  origenDesc,
  origenDesc,
  30000,
  true,
  true,
  "",
  "https://www.origenfs.co.uk/guiide-individual-advice/",
  "origen-financial-services-logo.jpg"
);

const wpsDesc = new Array<string>();
wpsDesc.push("At WPS Advisory we use our knowledge and experience to help you make informed decisions.");
wpsDesc.push("First and foremost we talk to you about you and your plans, not just financial but emotional, and only then can we advise you with regards to your money and how it can be used to help you do the things you consider important.");
wpsDesc.push("Our unique and customer focused approach has recently been recognised with the award of 'Best Retirement Adviser 2022' by Pan Finance International.");
const wps = new Advisor(
  "WPS",
  wpsDesc,
  wpsDesc,
  0,
  true,
  false,
  "",
  "https://www.wpsadvisory.com",
  "wpsa-logo.png"
);

advisors.all.push(origenAdvisor);
advisors.all.push(hubfs);
advisors.all.push(wps);

export { Advisor, Advisors, advisors };
