import AnswerValidator from "./answer-validator";

class AnswerValidationResponse {
  public isValid = false;
  public invalidReason = "";

  static Valid(): AnswerValidationResponse {
    return new AnswerValidationResponse(null);
  }

  static DefaultFailureResponse(): AnswerValidationResponse {
    return new AnswerValidationResponse(
      "Please answer this question"
    );
  }

  public constructor(invalidReason = "") {
    this.invalidReason = invalidReason;
    this.isValid = invalidReason == null || invalidReason.length == 0;
  }
}

export default AnswerValidationResponse;
