class StatePensionData {
  public amount: number;
  public entitlementDate: string;

  public constructor(amount: number, entitlementDate: string) {
    this.amount = amount;
    this.entitlementDate = entitlementDate;
  }

  public hasPension(): boolean {
    return this.amount > 0;
  }
}

export { StatePensionData };
