
import { defineComponent, ref } from "vue";
import WorkflowSection from "../ts/workflow-section";
import api from "../ts/api";
import ServerOutcome from "@/db/ts/outcome";

export default defineComponent({
  name: "DevOptions",
  emits: ["key-questions-changed"],
  setup() {
    const devOptionYesOutcome = ref(null);

    return {
      devOptionYesOutcome
    };
  },
  methods: {
    setActiveWorkflowSection(workflowSection: WorkflowSection) {
      this.$store.commit("updateWorkflowSection", workflowSection);
    },
    onSkipToNextSectionClicked() {
      this.moveToNextSection();
    },
    moveToNextSection() {
      let workflowSectionNumber = this.$store.state.workflowSection as number;
      workflowSectionNumber += 1;
      const nextWorkflowSection = workflowSectionNumber as WorkflowSection;
      this.setActiveWorkflowSection(nextWorkflowSection);
    },
    onSkipToQuestionsClicked() {
      this.setActiveWorkflowSection(WorkflowSection.UserQuestions);
    },
    onSkipToCalcsClicked() {
      this.setActiveWorkflowSection(WorkflowSection.UserCalculations);
    },
    onGenerateCodesClicked() {
      this.$store.commit("setAdminMode");
    },
    changeOutcome() {
      this.$store.commit("updateYesOutcome", this.devOptionYesOutcome.checked);
    },
    async onSetTerminusClicked() {
      const setTerminusPromise = api.setTerminus(
        "AA111111",
        ServerOutcome.BlockedInQuestions
      );
      await setTerminusPromise;
    }
  }
});
