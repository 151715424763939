
import NavButtons from "@/db/components/NavButtons.vue";

export default {
  name: "NoOutcome",
  emits: ["show-retirement-advisors-required"],
  components: {
    NavButtons,
  },
  computed: {
    showGuiide: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "";
    },
    showOrigen: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "origen";
    },
    showAdvisorsText: function(): string {
      if (this.showOrigen) {
        return "General Retirement Advice";
      }
      return "Retirement Advisors";
    }
  },
};
