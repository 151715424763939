import IPotFactory from "./iPotFactory";
import Pot from "./pot";
import PotType from "./potType";

class SavingPotFactory implements IPotFactory {
  createPot(
    potNumber: number,
    currentValue: number,
    willMakeFutureContribs: boolean,
    monthlyFutureContrib: number,
    futureContribsWillIncrease: boolean,
    payableFrom: number
  ): Pot {
    const name = "Savings " + potNumber;
    return new Pot(
      PotType.Savings,
      potNumber,
      name,
      currentValue,
      willMakeFutureContribs,
      monthlyFutureContrib,
      futureContribsWillIncrease,
      payableFrom
    );
  }
}

const savingPotFactory = new SavingPotFactory();

export { savingPotFactory };
