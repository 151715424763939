import { formatter } from "../formatter";
import PotType from "./potType";

class Pot {
  public potType: PotType;
  public id: number;
  public name: string;
  public currentValue: number;
  public willMakeFutureContribs: boolean;
  public monthlyFutureContrib: number;
  public futureContribsWillIncrease: boolean;
  public payableFrom: number;

  public constructor(
    potType: PotType,
    id: number,
    name: string,
    currentValue: number,
    willMakeFutureContribs: boolean,
    monthlyFutureContrib: number,
    futureContribsWillIncrease: boolean,
    payableFrom: number
  ) {
    this.potType = potType;
    this.id = id;
    this.name = name;
    this.currentValue = currentValue;
    this.willMakeFutureContribs = willMakeFutureContribs;
    this.monthlyFutureContrib = monthlyFutureContrib;
    this.futureContribsWillIncrease = futureContribsWillIncrease;
    this.payableFrom = payableFrom;
  }

  public description(): string {
    const formattedValue = formatter.formatCurrency(this.currentValue);

    switch (this.potType) {
      case 0:
        return `a final salary pension of ${formattedValue} payable from ${this.payableFrom}`;
      case 1:
        return `a pension pot of ${formattedValue}`;
      case 2:
        return `a savings pot of ${formattedValue}`;
    }
  }
}

export default Pot;
