
import { defineComponent, ref, onMounted } from "vue";
import {
  PreviousQuestionResponse,
  NextQuestionResponse,
  Question,
  QuestionNavigationAction
} from "../ts/db-core";
import WorkflowSection from "../ts/workflow-section";
import { questions, workflowEventHandler } from "../ts/db-questions";
import { questionNavigator } from "../ts/questionNavigator";
import Landing from "@/db/components/Landing.vue";
import Philosophy from "@/db/components/Philosophy.vue";
import Start from "@/db/components/Start.vue";
import Identify from "@/db/components/Identify.vue";
import EducationalVideo from "@/db/components/EducationalVideo.vue";
import QuestionView from "@/db/components/QuestionView.vue";
import Breadcrumb from "@/db/components/Breadcrumb.vue";
import Haves from "@/db/components/Haves.vue";
import Risk from "@/db/components/Risk.vue";
import Outcome from "@/db/components/Outcome.vue";
import TerminusGuiide from "@/db/components/Terminus.vue";
import TerminusOrigen from "./TerminusOrigen.vue";
import DevOptions from "@/db/components/DevOptions.vue";
import Admin from "@/db/components/Admin.vue";
import api from "../ts/api";
import ServerOutcome from "../ts/outcome";
import OutcomeEvaluator from "../ts/outcomeEvaluator";

export default defineComponent({
  name: "GuiideDb",
  components: {
    Landing,
    Philosophy,
    Start,
    Identify,
    EducationalVideo,
    QuestionView,
    Breadcrumb,
    Haves,
    Risk,
    Outcome,
    TerminusGuiide,
    TerminusOrigen,
    DevOptions,
    Admin
  },
  setup() {
    const aQuestionView = ref(null);
    const terminus = ref(null);

    async function setNextQuestion(nextQuestion: Question) {
      aQuestionView.value.fadeOutQuestion();

      await new Promise(res => {
        setTimeout(res, 750);
      });

      aQuestionView.value.reset();
      this.$data.question = nextQuestion;
      this.$data.isFirstQuestion = questionNavigator.isFirstQuestion(
        nextQuestion
      );

      aQuestionView.value.fadeInQuestion();
    }

    return {
      aQuestionView,
      terminus,
      setNextQuestion
    };
  },
  data() {
    return {
      WorkflowSection,
      question: questionNavigator.getFirstQuestion(),
      isFirstQuestion: true
    };
  },
  watch: {
    isOrigen(val: boolean) {
      if (val) {
        questionNavigator.configureForOrigen();
      }
    }
  },
  computed: {
    showBreadcrumbs() {
      const isInLanding = this.isWorkflowSectionActive(WorkflowSection.Landing);
      const isInPhilosophy = this.isWorkflowSectionActive(
        WorkflowSection.Philosophy
      );
      const isInStart = this.isWorkflowSectionActive(WorkflowSection.Start);
      const isInIdentify = this.isWorkflowSectionActive(
        WorkflowSection.Identify
      );
      return !(isInLanding || isInPhilosophy || isInStart || isInIdentify);
    },
    showLanding() {
      return this.isWorkflowSectionActive(WorkflowSection.Landing);
    },
    showPhilosophy() {
      return this.isWorkflowSectionActive(WorkflowSection.Philosophy);
    },
    showStart() {
      return this.isWorkflowSectionActive(WorkflowSection.Start);
    },
    showIdentify() {
      return this.isWorkflowSectionActive(WorkflowSection.Identify);
    },
    showEducationVideo() {
      return this.isWorkflowSectionActive(WorkflowSection.EducationVideo);
    },
    showUserQuestions() {
      return this.isWorkflowSectionActive(WorkflowSection.UserQuestions);
    },
    showTerminusGuiide() {
      return this.isWorkflowSectionActive(WorkflowSection.Terminus) && !this.isOrigen;
    },
    showTerminusOrigen() {
      return this.isWorkflowSectionActive(WorkflowSection.Terminus) && this.isOrigen;
    },
    showCalculation() {
      return this.isWorkflowSectionActive(WorkflowSection.UserCalculations);
    },
    showRisk() {
      return this.isWorkflowSectionActive(WorkflowSection.RiskInsights);
    },
    showOutcome() {
      return this.isWorkflowSectionActive(WorkflowSection.FCAStatement);
    },
    showDPanel() {
      return this.$store.state.dPanel;
    },
    isOrigen: function (): boolean {
      return this.$store.state.configuration.advisorFilter === "origen";
    },
    showAdmin() {
      return this.$store.state.adminMode;
    }
  },
  methods: {
    setActiveWorkflowSection(workflowSection: WorkflowSection) {
      this.$store.commit("updateWorkflowSection", workflowSection);
    },
    isWorkflowSectionActive(workflowSection) {
      if (this.$store.state.adminMode) {
        return false;
      }
      return this.$store.state.workflowSection == workflowSection;
    },
    onEducationalVideoNextClicked() {
      this.setActiveWorkflowSection(WorkflowSection.UserQuestions);
    },
    async onAnswered(question: Question) {
      const nextQuestionResponse: NextQuestionResponse = questionNavigator.getNextQuestion(
        question
      );

      let setTerminusPromise = null;
      const userId = this.$store.state.userId;

      switch (nextQuestionResponse.questionNavigationAction) {
        case QuestionNavigationAction.CurrentQuestionUnanswered:
          return;
        case QuestionNavigationAction.NextQuestion:
          workflowEventHandler.QuestionAnswered(question);
          this.setNextQuestion(nextQuestionResponse.nextQuestion);
          break;
        case QuestionNavigationAction.UserBlocked:
          setTerminusPromise = api.setTerminus(
            userId,
            nextQuestionResponse.outcome
          );
          await setTerminusPromise;
          this.terminus.setBlockedQuestionId(question.id);
          this.setActiveWorkflowSection(WorkflowSection.Terminus);
          break;
        case QuestionNavigationAction.QuestionsCompleted:
          this.setActiveWorkflowSection(nextQuestionResponse.workflowSection);
          break;
        default:
          throw new Error("Unknown QuestionNavigationAction");
      }
    },
    onQuestionPreviousClicked() {
      const previousQuestionResponse: PreviousQuestionResponse = questionNavigator.getPreviousQuestion();

      if (previousQuestionResponse.noPreviousQuestion) {
        this.setActiveWorkflowSection(WorkflowSection.EducationVideo);
        return;
      }

      this.setNextQuestion(previousQuestionResponse.previousQuestion);
    },
    onHavesPreviousClicked() {
      this.setActiveWorkflowSection(WorkflowSection.UserQuestions);
    },
    onHavesNextClicked() {
      this.setActiveWorkflowSection(WorkflowSection.RiskInsights);
    },
    async moveToNextSection() {
      let workflowSectionNumber = this.$store.state.workflowSection as number;
      workflowSectionNumber += 1;
      const nextWorkflowSection = workflowSectionNumber as WorkflowSection;

      if (nextWorkflowSection == WorkflowSection.FCAStatement) {
        await this.evaluateOutcome();
      }

      this.setActiveWorkflowSection(nextWorkflowSection);
    },
    onKeyQuestionsChanged(value) {
      questionNavigator.setShowKeyQuestionsOnly(value);
      this.question = questionNavigator.getFirstQuestion();
    },
    onLandingNextClicked() {
      console.log("onLandingNextclicked");
      this.setActiveWorkflowSection(WorkflowSection.Start);
    },
    onStartNextClicked() {
      const nextSection = this.isOrigen ? WorkflowSection.EducationVideo : WorkflowSection.Identify;
      this.setActiveWorkflowSection(nextSection);
    },
    onIdentifyNextClicked() {
      const serverOutcome: ServerOutcome = this.$store.state.serverOutcome;
      if (serverOutcome == ServerOutcome.BlockedInQuestions) {
        this.setActiveWorkflowSection(WorkflowSection.Terminus);
        this.terminus.setBlockedQuestionId(
          questionNavigator.getIllHealthQuestion().id
        );
      } else if (serverOutcome == ServerOutcome.TransferValueTooLow) {
        this.setActiveWorkflowSection(WorkflowSection.Terminus);
        this.terminus.setBlockedQuestionId(
          questionNavigator.getTransferValueTooLowQuestion().id
        );
      } else if (
        serverOutcome == ServerOutcome.NotAppropriate ||
        serverOutcome == ServerOutcome.ShownAdvisors
      ) {
        this.setActiveWorkflowSection(WorkflowSection.FCAStatement);
      } else {
        this.setActiveWorkflowSection(WorkflowSection.EducationVideo);
      }
    },
    onTerminusGoBackToStart() {
      this.setActiveWorkflowSection(WorkflowSection.UserQuestions);
      this.question = questionNavigator.getFirstQuestion();
    },
    async evaluateOutcome() {
      const outcomeEvaluator = new OutcomeEvaluator();
      await outcomeEvaluator.UserShouldConsiderTransfer();
    }
  }
});
