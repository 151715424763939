import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "bi bi-arrow-right"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 0,
  class: "bi bi-arrow-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.nextVisible)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: _normalizeClass(_ctx.getButtonClass(_ctx.nextMuted)),
            disabled: !_ctx.nextEnabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next-clicked')))
          }, [
            _createTextVNode(_toDisplayString(_ctx.nextText) + " ", 1),
            (_ctx.nextShowArrow)
              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
              : _createCommentVNode("", true)
          ], 10, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.previousVisible)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: _normalizeClass(_ctx.getButtonClass(_ctx.previousMuted)),
          disabled: !_ctx.previousEnabled,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('previous-clicked')))
        }, [
          (_ctx.previousShowArrow)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.previousText), 1)
        ], 10, _hoisted_4))
      : _createCommentVNode("", true)
  ], 64))
}