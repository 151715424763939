
import { defineComponent, ref } from "vue";
import api from "../ts/api";
import ServerOutcome from "../ts/outcome";
import { storedAnswerResponseReader } from "@/db/ts/storedAnswerResponseReader";

export default defineComponent({
  name: "Identify",
  emits: ["next-clicked"],
  setup() {
    const userCode = ref(null);

    return {
      userCode
    };
  },
  data() {
    return {
      showInvalidCodeMessage: false
    };
  },
  methods: {
    async onNextClicked() {
      const isValidCode = await this.isValid(this.userCode.value);
      if (!isValidCode) {
        this.showInvalidCodeMessage = true;
        return;
      }
      this.showInvalidCodeMessage = false;
      this.$emit("next-clicked");
    },
    async isValid(code: string): Promise<boolean> {
      if (code == null) {
        return false;
      }

      code = code.trim();
      if (code.length == 0) {
        return false;
      }

      let codeIsValid = false;
      const accessCodePromise = api.verifyAccessCode(code);
      await accessCodePromise
        .then((res: any) => {
          codeIsValid = res.data.Data.isValidCode;

          if (codeIsValid) {
            this.$store.commit("updateUserId", code);
            let outCome: ServerOutcome = ServerOutcome.NotReached;
            if (res.data.Data.outCome == "BlockedInQuestions") {
              outCome = ServerOutcome.BlockedInQuestions;
            } else if (res.data.Data.outCome == "NotAppropriate") {
              outCome = ServerOutcome.NotAppropriate;
            } else if (res.data.Data.outCome == "ShownAdvisors") {
              outCome = ServerOutcome.ShownAdvisors;
            } else if (res.data.Data.outCome == "TransferValueTooLow") {
              outCome = ServerOutcome.TransferValueTooLow;
            }

            const storedAnswerResponse = storedAnswerResponseReader.read(res);

            this.$store.commit("updateServerOutcome", outCome);
            this.$store.commit(
              "updateServerAnswers",
              storedAnswerResponse.serverAnswers
            );
            this.$store.commit(
              "updateServerPots",
              storedAnswerResponse.serverPots
            );
            this.$store.commit(
              "updateServerStatePension",
              storedAnswerResponse.statePension
            );

            const optionsCode = ["KH", "SL", "CR"];
            if (
              optionsCode
                .map(o => "DV" + o)
                .filter(o => o === code.toUpperCase()).length > 0
            ) {
              this.$store.commit("updateDPanel", true);
            }
          }
        })
        .catch(() => {
          codeIsValid = false;
        });

      return codeIsValid;
    }
  },
  mounted() {
    this.userCode.focus();
  }
});
