
import { defineComponent } from "vue";

export default defineComponent({
  name: "Landing",
  emits: ["next-clicked"],
  methods: {
    onNextClicked() {
      this.$emit("next-clicked");
    }
  }
});
