
import { Answer } from "@/db/ts/db-core";
import { ref } from "vue";
import AnswerPresentationType from "../ts/answer-presentation-type";
import Datepicker from "vue3-datepicker";
import { inputValidator } from "../ts/inputValidator";
import CurrencyInput from "@/db/components/CurrencyInput.vue";
import { formatter } from "../ts/formatter";

export default {
  name: "Answer",
  components: {
    Datepicker,
    CurrencyInput
  },
  props: {
    questionId: { type: Number, required: true },
    answer: { type: Answer, required: true }
  },
  methods: {
    getCheckedState(): boolean {
      if (!this.answer.responseGiven) {
        return false;
      }
      return this.answer.response === this.answer.delegatedResponse;
    },
    getAnsweredText(): string {
      if (!this.answer.responseGiven) {
        return this.answer.hasDefaultResponse
          ? this.answer.defaultResponse
          : "";
      }
      return this.answer.response;
    },
    bindAnswer() {
      if (this.answer.presentationType == AnswerPresentationType.Radio) {
        this.answer.bindResponse(this.radioInput.checked);
      } else if (this.answer.presentationType == AnswerPresentationType.Text) {
        this.answer.bindResponse(this.crrncyInput.getValue());
      } else if (
        this.answer.presentationType == AnswerPresentationType.DatePicker
      ) {
        const selectedDate = new Date(this.dateInput);
        this.answer.bindResponse(selectedDate);
      } else {
        throw new Error("Unknowns input type");
      }
    },
    getAnswerKey(answer: Answer) {
      return this.questionId + "-" + answer.id;
    },
    highlightInvalidAnswer() {
      if (this.answer.presentationType == AnswerPresentationType.Radio) return;

      const answerValidationResponse = this.answer.isValidResponse();
      this.flagInvalidAnswer = !answerValidationResponse.isValid;
      this.validationFailureMessage = answerValidationResponse.invalidReason;
    },
    onChanged() {
      this.$emit("answer-changed", this);
    }
  },
  mounted() {
    if (this.answer.presentationType === AnswerPresentationType.Radio) {
      this.radioInput.focus();
    }
  },
  setup(props) {
    const radioInput = ref(null);
    const crrncyInput = ref(null);

    let dateInput = ref(null);
    if (props.answer.presentationType === AnswerPresentationType.DatePicker) {
      dateInput = ref(props.answer.response);
    }

    const flagInvalidAnswer = ref(false);
    const validationFailureMessage = "";

    return {
      radioInput,
      crrncyInput,
      dateInput,
      flagInvalidAnswer,
      AnswerPresentationType,
      validationFailureMessage,
      inputValidator,
      formatter
    };
  }
};
