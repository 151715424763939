
import { ref } from "vue";
import { inputValidator } from "../ts/inputValidator";
import { formatter } from "../ts/formatter";

export default {
  name: "CurrencyInput",
  props: {
    description: String,
    elementId: String,
    currentValue: String
  },
  data() {
    return {
      receivedFocusFromMouse: false
    };
  },
  methods: {
    lostfocus() {
      this.displayFormattedValue();
    },
    onEnterPressed() {
      this.displayFormattedValue();
      this.$emit("entered");
    },
    displayFormattedValue() {
      if (this.textInput === null) {
        return;
      }

      const integerValue = parseInt(this.textInput.value);
      if (isNaN(integerValue) || integerValue === undefined) {
        this.textInput.value = "";
        return;
      }

      this.textInput.value = formatter.formatCurrencyForInput(integerValue);
    },
    isFormatted() {
      return (this.textInput.value.match(/,/gi) || []).length > 0;
    },
    onMousedown() {
      this.receivedFocusFromMouse = true;
    },
    onMouseup(event) {
      this.receivedFocusFromMouse = false;

      if (!this.isFormatted()) {
        return;
      }

      const valueToLeftOfCursor = this.textInput.value.substring(
        0,
        event.target.selectionStart
      );
      const commaCount = (valueToLeftOfCursor.match(/,/gi) || []).length;
      const strippedCharCount = commaCount;
      const newCursorIndex = Math.max(
        0,
        event.target.selectionStart - strippedCharCount
      );

      this.displayNumericValue(event, newCursorIndex, newCursorIndex);
    },
    displayNumericValue(event, selectionStart: number, selectionEnd: number) {
      this.changing = true;

      if (this.canGetAmountAsInteger()) {
        this.textInput.value = this.getAmountAsInteger();
      } else {
        this.textInput.value = "";
      }

      event.target.selectionStart = selectionStart;
      event.target.selectionEnd = selectionEnd;
    },
    printCursorInfo(source, event) {
      console.log(
        `${source}`,
        `${event.target.selectionStart},${event.target.selectionEnd}`
      );
      const inputElement = document.getElementById(
        this.textInput.id
      ) as HTMLInputElement;
      console.log("\t element.selectionStart", inputElement.selectionStart);
    },
    gotfocus(event) {
      if (!this.receivedFocusFromMouse) {
        this.displayNumericValue(event, 0, 1000);
      }
    },
    getAmountAsInteger(): number {
      let integerValue = this.textInput.value;

      if (integerValue.length == 0 || integerValue == "£") {
        return 0;
      }

      integerValue = integerValue.replace(/,/gi, "").replace(/£/gi, "");
      return integerValue;
    },
    getValue(): string {
      if (this.canGetAmountAsInteger()) {
        return this.getAmountAsInteger();
      } else {
        return "";
      }
    },
    canGetAmountAsInteger(): boolean {
      if (this.textInput.value.trim().length === 0) {
        return false;
      }

      const integerValue = parseInt(this.textInput.value);
      if (isNaN(integerValue) || integerValue === undefined) {
        return false;
      }

      return true;
    }
  },
  setup() {
    const textInput = ref(null);

    return { textInput, inputValidator, formatter };
  },
  mounted() {
    this.textInput.focus();
  }
};
