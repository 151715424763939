class StoredAnswer {
  constructor(questionId: number, responses: string[]) {
    this.questionId = questionId;
    this.responses = responses;
  }

  public questionId: number;
  public responses: string[];
}

export { StoredAnswer };
