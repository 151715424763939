
import { defineComponent } from "vue";
import NavElement from "@/db/components/NavElement.vue";
import WorkflowSection from "../ts/workflow-section";

export default defineComponent({
  name: "Breadcrumb",
  components: {
    NavElement
  },
  data() {
    return {
      WorkflowSection
    };
  }
});
