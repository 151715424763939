
import { defineComponent } from "vue";
import GuiideDb from "./components/GuiideDb.vue";
import { app } from "./ts/app";

export default defineComponent({
  name: "App",
  components: {
    GuiideDb
  },
  created() {
    app.initialise();
  }
});
