
import { defineComponent, ref } from "vue";
import Pot from "@/db/ts/assets/pot";
import Modal from "@/db/components/Modal.vue";
import PotEditor from "@/db/components/assets/PotEditor.vue";
import IPotFactory from "@/db/ts/assets/iPotFactory";
import { formatter } from "@/db/ts/formatter";

export default defineComponent({
  name: "PotList",
  components: {
    Modal,
    PotEditor
  },
  props: {
    assetType: { type: String, required: true },
    assetName: { type: String, required: true },
    addLabel: { type: String, required: true },
    potFactory: { type: Object as () => IPotFactory, required: true },
    currentValueLabel: { type: String, default: "Current value" },
    capturePayableFrom: { type: Boolean, default: false }
  },
  setup() {
    const potModal = ref(null);
    const potEditor = ref(null);

    return {
      potModal,
      potEditor
    };
  },
  data() {
    return {
      pots: new Array<Pot>(),
      formatter
    };
  },
  methods: {
    getPots(): Array<Pot> {
      return this.pots;
    },
    onAddPotClick(event) {
      event.preventDefault();
      const nextPotNumber = this.getNextPotNumber();
      const pot = this.potFactory.createPot(nextPotNumber, 0);
      const isNew = true;
      this.potEditor.setValues(
        isNew,
        nextPotNumber,
        pot.name,
        0,
        false,
        0,
        false,
        this.capturePayableFrom,
        new Date().getFullYear()
      );
      this.potModal.showModal();
    },
    getNextPotNumber() {
      if (!this.pots) {
        return 1;
      }
      let nextPotNumber = 1;
      this.pots.forEach(pot => {
        if (pot.id >= nextPotNumber) {
          nextPotNumber = pot.id + 1;
        }
      });
      return nextPotNumber;
    },
    onPotCancelClicked() {
      this.potModal.closeModal();
    },
    onPotSaveClicked() {
      if (this.potEditor.isNew) {
        const newPot = this.potEditor.getPot();
        this.pots.push(newPot);
      } else {
        const pot = this.getPotById(this.potEditor.id);
        this.potEditor.updatePot(pot);
        console.log("updated pot", pot);
      }
      this.potModal.closeModal();
    },
    getPotById(id: number) {
      return this.pots.filter(p => p.id == id)[0];
    },
    onEditPotClick(event, name: string) {
      event.preventDefault();
      const pot = this.pots.filter(p => p.name == name)[0];
      const isNew = false;
      this.potEditor.setValues(
        isNew,
        pot.id,
        pot.name,
        pot.currentValue,
        pot.willMakeFutureContribs,
        pot.monthlyFutureContrib,
        pot.futureContribsWillIncrease,
        this.capturePayableFrom,
        pot.payableFrom
      );
      this.potModal.showModal();
    },
    onDeleteClicked(id: number) {
      const potToDelete = this.getPotById(id);
      const index = this.pots.indexOf(potToDelete, 0);
      if (index > -1) {
        this.pots.splice(index, 1);
      }
    }
  }
});
