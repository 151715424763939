enum WorkflowSection {
  Landing,
  Philosophy,
  Start,
  Introduction,
  Identify,
  EducationVideo,
  UserQuestions,
  Terminus,
  UserCalculations,
  RiskInsights,
  FCAStatement
}

export default WorkflowSection;
