import { StatePensionData } from "./api/statePensionData";
import Pot from "./assets/pot";
import PotType from "./assets/potType";
import { StoredAnswer } from "./storedAnswer";

class StoredAnswerResponse {
  public serverAnswers: Array<StoredAnswer>;
  public serverPots: Array<Pot>;
  public statePension: StatePensionData;
}

class StoredAnswerResponseReader {
  public read(res: any): StoredAnswerResponse {
    const serverAnswers = new Array<StoredAnswer>();
    const serverPots = new Array<Pot>();
    let statePensionData = new StatePensionData(0, "");

    if (res.data.Data.responses != null) {
      res.data.Data.responses.forEach(element => {
        const storedAnswer = new StoredAnswer(
          element.QuestionId,
          element.Responses
        );
        serverAnswers.push(storedAnswer);
      });

      res.data.Data.otherPots.forEach((element, i) => {
        let potType: PotType;

        switch (element.PotType) {
          case 0:
            potType = PotType.DefinedBenefit;
            break;
          case 1:
            potType = PotType.Pension;
            break;
          case 2:
            potType = PotType.Savings;
            break;
        }

        const otherPot = new Pot(
          potType,
          i,
          `${potType}${i}`,
          element.Value,
          false,
          0,
          false,
          element.PayableFrom
        );
        serverPots.push(otherPot);
      });

      statePensionData = new StatePensionData(
        res.data.Data.statePension.amount,
        res.data.Data.statePension.entitlementDate
      );
    }

    const storedAnswerResponse = new StoredAnswerResponse();
    storedAnswerResponse.serverAnswers = serverAnswers;
    storedAnswerResponse.serverPots = serverPots;
    storedAnswerResponse.statePension = statePensionData;
    return storedAnswerResponse;
  }
}

const storedAnswerResponseReader = new StoredAnswerResponseReader();

export { StoredAnswerResponse, storedAnswerResponseReader };
